import { LOCALES } from './locales'

export const WEATHER_CONDITIONS = {
    [LOCALES.ENGLISH]: {
      clear: "Clear",
      "partly cloudy": "Partly cloudy",
      cloudy: "Cloudy",
      rain: "Rain",
      drizzle: "Drizzle",
      hail: "Hail",
      thunderstorm: "Thunderstorm",
      snow: "Snow",
      fog: "Fog",
      haze: "Haze",
      sand: "Sand",
      dust: "Dust",
      smoke: "Smoke",
      ash: "Ash",
      squalls: "Squalls",
      unknown: "Unknown",
      undefined: "Unknown",
      chance: "Chance",
    },
    [LOCALES.SPANISH]: {
      clear: "Despejado",
      "partly cloudy": "Parcialmente nublado",
      cloudy: "Nublado",
      rain: "Lluvia",
      drizzle: "Llovizna",
      hail: "Granizo",
      thunderstorm: "Tormenta eléctrica",
      snow: "Nieve",
      fog: "Niebla",
      haze: "Neblina",
      sand: "Arena",
      dust: "Polvo",
      smoke: "Humo",
      ash: "Ceniza",
      squalls: "Chubascos",
      unknown: "Desconocido",
      undefined: "Desconocido",
      chance: "Probabilidad",
    },
    [LOCALES.FRENCH]:
    {
      clear: "Dégagé",
      "partly cloudy": "Partiellement nuageux",
      cloudy: "Nuageux",
      rain: "Pluie",
      drizzle: "Bruine",
      hail: "Grêle",
      thunderstorm: "Orage",
      snow: "Neige",
      fog: "Brouillard",
      haze: "Brume",
      sand: "Sable",
      dust: "Poussière",
      smoke: "Fumée",
      ash: "Cendre",
      squalls: "Rafales",
      unknown: "Inconnu",
      undefined: "Inconnu",
      chance: "Chance",
    },
    [LOCALES.POLISH]:
    {
      clear: "Bezchmurnie",
      "partly cloudy": "Częściowe zachmurzenie",
      cloudy: "Zachmurzenie",
      rain: "Deszcz",
      drizzle: "Mżawka",
      hail: "Grad",
      thunderstorm: "Burza z piorunami",
      snow: "Śnieg",
      fog: "Mgła",
      haze: "Zadymienie",
      sand: "Piasek",
      dust: "Kurz",
      smoke: "Dym",
      ash: "Popiół",
      squalls: "Porywy wiatru",
      unknown: "Nieznany",
      undefined: "Nieznany",
      chance: "Szansa",
    },
    [LOCALES.ITALIAN]:
    {
      clear: "Sereno",
      "partly cloudy": "Parzialmente nuvoloso",
      cloudy: "Nuvoloso",
      rain: "Pioggia",
      drizzle: "Pioggerella",
      hail: "Grandine",
      thunderstorm: "Temporale",
      snow: "Neve",
      fog: "Nebbia",
      haze: "Nebbia sottile",
      sand: "Sabbia",
      dust: "Polvere",
      smoke: "Fumo",
      ash: "Cenere",
      squalls: "Raffiche di vento",
      unknown: "Sconosciuto",
      undefined: "Sconosciuto",
      chance: "Possibilità",
    },
    [LOCALES.GERMAN]:
    {
      clear: "Klar",
      "partly cloudy": "Teilweise bewölkt",
      cloudy: "Bewölkt",
      rain: "Regen",
      drizzle: "Nieselregen",
      hail: "Hagel",
      thunderstorm: "Gewitter",
      snow: "Schnee",
      fog: "Nebel",
      haze: "Dunst",
      sand: "Sand",
      dust: "Staub",
      smoke: "Rauch",
      ash: "Asche",
      squalls: "Windböen",
      unknown: "Unbekannt",
      undefined: "Unbekannt",
      chance: "Chance",
    }
  }