import Element from "./Element";

import { interpolateOpacity } from "../helpers";

function Slide({
  slide,
  currentTime,
  playing,
  syncPlayback,
  location,
  player,
  lang,
  params,
  creative,
}) {
  const { timeline } = slide;
  const style = { opacity: interpolateOpacity(currentTime, timeline) };
  return (
    <div className="slide" style={style}>
      {slide.elements.map((element) => {
        return (
          <Element
            key={element.name}
            element={element}
            currentTime={currentTime}
            playing={playing}
            syncPlayback={syncPlayback}
            player={player}
            location={location}
            lang={lang}
            params={params}
            creative={creative}
          />
        );
      })}
    </div>
  );
}

export default Slide;
