import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const NavStepIcon = ({ size, backgroundColor, borderColor }) => {
  let customSize = 35;
  let customBorder = 2;
  let customMargin = 'm-md';

  if (size < 500) {
    customSize = 20;
    customBorder = 1;
    customMargin = 'm-xs';
  } else if (size > 1280) {
    customSize = 60;
    customBorder = 3;
    customMargin = 'm-lg';
  }
  return (
    <svg
      // styleName={`svg ${customMargin}`}
      width={`${customSize}px`}
      height={`${customSize}px`}
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs></defs>
      <g
        id="nav-item"
        stroke="none"
        strokeWidth={customBorder}
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M20,38.5 C30.2172679,38.5 38.5,30.2172679 38.5,20 C38.5,9.78273213 30.2172679,1.5 20,1.5 C9.78273213,1.5 1.5,9.78273213 1.5,20 C1.5,30.2172679 9.78273213,38.5 20,38.5 Z M20,40 C8.954305,40 0,31.045695 0,20 C0,8.954305 8.954305,0 20,0 C31.045695,0 40,8.954305 40,20 C40,31.045695 31.045695,40 20,40 Z"
          id="nav-item-border"
          fill={borderColor}
          fillRule="evenodd"
        ></path>
        <path
          d="M20,38.5 C30.2172679,38.5 38.5,30.2172679 38.5,20 C38.5,9.78273213 30.2172679,1.5 20,1.5 C9.78273213,1.5 1.5,9.78273213 1.5,20 C1.5,30.2172679 9.78273213,38.5 20,38.5 Z"
          id="nav-item-fill"
          fill={backgroundColor}
        ></path>
      </g>
    </svg>
  );
};

NavStepIcon.propTypes = {
  size: PropTypes.number,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string
};

export default NavStepIcon;
