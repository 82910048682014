import React, { useEffect, useRef } from "react";
import { parse } from "query-string";

const { REACT_APP_ENABLED_VIDEO_FORMAT = "none" } = process.env;
const MS = 1000;
const PLAYER_FRAMERATE = 1 / 30;
// const RELOAD_INTERVAL = 60 * MS;

function VideoElement({ element, player, syncPlayback, playing, visible }) {
  const { sources, style, loop } = element;
  const { preload = "none" } = player;
  const query = parse(window.location.search);
  const allFormats = query.allFormats;

  const videoEl = useRef(null);

  const sourcesType =
    REACT_APP_ENABLED_VIDEO_FORMAT && !allFormats
      ? sources.filter((source) =>
          REACT_APP_ENABLED_VIDEO_FORMAT.includes(source.type)
        )
      : sources;

  useEffect(() => {
    if (!videoEl) {
      return;
    }

    let interval;
    let videoRef = videoEl.current;
    // Truco para evitar rotaciones vacías en la creatividad 5b3f11dac9b2f0316c9cc108
    const { source, sources = [], sync } = element;

    if (query.checkForm && source && query.checkForm === source.form) {
      const urls = sources.filter((s) => !!s.url);

      if (!urls.length) {
        document.location.reload();
      }
    }
    videoRef.currentTime = 0;

    if (sync) {
      interval = setInterval(() => {
        syncPlayback(videoRef.currentTime);
      }, PLAYER_FRAMERATE * MS);
    }

    return function () {
      clearInterval(interval);
      videoRef.currentTime = 0;
    };
  }, [element, videoEl, query.checkForm, syncPlayback]);

  useEffect(() => {
    if (playing && visible) {
      videoEl.current.play();
    } else {
      videoEl.current.pause();
    }
  }, [playing, visible]);

  return (
    <div>
      {sourcesType.map((source, index) => {
        return (
          <link
            key={index}
            rel="preload"
            as="video"
            href={source.url}
            type={source.type}
          />
        );
      })}
      <video
        ref={videoEl}
        width={style.width}
        height={style.height}
        preload={preload}
        loop={loop === undefined ? true : loop}
        muted
      >
        {sourcesType.map((source, index) => {
          return <source key={index} src={source.url} type={source.type} />;
        })}
      </video>
    </div>
  );
}

export default VideoElement;
