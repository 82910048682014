import React, { useEffect, useState, useCallback } from "react";
import { parse, stringify } from "query-string";
import dotize from "dotize";
import { checkVisibilityRules } from "d3ck-lib";
import Slide from "./components/Slide";

import {
  api,
  headers,
  getProjection,
  isDoubled,
  transformCreative,
  caclTimeline,
  API_VERSIONS,
} from "./helpers";

import "./Player.css";

const PLAYER_FRAMERATE = 1 / 30;
const MS = 1000;

function isPreview() {
  return document.location.pathname.indexOf("/preview") === 0;
}

function getPreviewCorners() {
  const baseWidth = 5228;
  // const baseHeight = 3484;
  const currentWidth = window.innerWidth;
  // const currentHeight = window.innerHeight;
  const scale = currentWidth / baseWidth;

  return {
    x1: 3402 * scale,
    y1: 442 * scale,
    x2: 4480 * scale,
    y2: 274 * scale,
    x3: 3402 * scale,
    y3: 2136 * scale,
    x4: 4480 * scale,
    y4: 2194 * scale,
  };
}

function enrichLocation(location) {
  const query = parse(window.location.search);

  if (query.weather) {
    location = { ...location, condition: query.weather };
  }

  if (query.temperature) {
    location = { ...location, temperature: query.temperature };
  }

  if (query.uv) {
    location = { ...location, uv: query.uv };
  }

  if (query.aqi) {
    location = { ...location, air: query.aqi };
  }

  if (query.city) {
    location = { ...location, city: query.city };
  }

  if (query.postalCode) {
    location = { ...location, postalCode: query.postalCode };
  }

  if (query.country) {
    location = { ...location, country: query.country };
  }

  return location;
}

function Player({ lang = parse(document.location.search).lang, creativeId, playlist }) {
  const [creative, setCreative] = useState(null);
  const [creativeStatus, setCreativeStatus] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [location, setLocation] = useState(null);
  const [player, setPlayer] = useState(null);
  const [slide, setSlide] = useState();

  const fetchCreative = useCallback(async () => {
    const query = parse(document.location.search);
    const baseUrl = `${api}/player/creativity/${creativeId}`;

    const options = {
      method: "GET",
      headers: headers(),
    };

    const params = {
      ip: query.ip,
      address: query.address,
      bsPlayerId:
        query.ResourceID ||
        query["com_broadsign_suite_bsp_resource_id"] ||
        query["com.broadsign.suite.bsp.resource_id"] ||
        window.BroadSignObject?.player_id ||
        process.env.REACT_APP_DEFAULT_BSPLAYER_ID,
      frameId:
        window.BroadSignObject?.frame_id ||
        query.frameId ||
        query.frame_id ||
        query["com.broadsign.suite.bsp.frame_id"],
      lang: query.lang,
      audienceForecast: query['location.audienceForecast'],
      currentAffluence: query['location.currentAffluence']
    };

    console.log("BroadSignObject: ", window.BroadSignObject);

    const response = await window.fetch(
      `${baseUrl}?${stringify(dotize.convert(params))}`,
      options
    );

    if (!response.ok) {
      const responseText = await response.text();
      throw new Error(responseText);
    }

    return response.json();
  }, [creativeId]);

  const initPlayer = useCallback(async () => {
    try {
      const data = await fetchCreative(creativeId);
      const creativity =
        process.env.REACT_APP_API_VERSION === API_VERSIONS.d3ck
          ? data.creativity
          : data.instance;
      const { location , trafficEstimate, trafficGrabber, player, sport, languages, forms } = data

      setLocation(enrichLocation(location));
      setPlayer(player);
      setCreative(caclTimeline(creativity, enrichLocation(location), trafficEstimate, sport, trafficGrabber, languages, forms));
      setCreativeStatus(200);
    } catch (error) {
      setLocation(null);
      setPlayer(null);
      setCreative(null);
      setCreativeStatus(500);
      console.error(error);
    }
  }, [creativeId, fetchCreative]);

  const initPlaylistPlayer = useCallback(async () => {
    const url = `${api}/player/creativity/${creativeId}`;
    const options = {
      method: "GET",
      headers: headers(),
    };

    window.fetch(url, options)
      .then(response => response.json())
      .then((response) => {
        console.log(response);
        const {
          playlists,
          location,
          forms,
          weathers,
          query,
          player,
          creativity,
        } = response;

        const playlistWithVisibilityRules = playlists.find((playlist) => {
          const formattedPlaylistRules = playlist.rules.map((rule) => ({
            ...rule,
            value: rule.value.split(","),
          }));
          const props = { location, forms, weathers, query };

          return checkVisibilityRules(formattedPlaylistRules, props);
        });

        if (playlistWithVisibilityRules) {
          const slides = playlistWithVisibilityRules.slides;
          let nextSlideAnchor;
          const slideStoraged = localStorage.getItem(
            `${playlistWithVisibilityRules._id}_last-slide`
          );

          if (!slideStoraged) {
            nextSlideAnchor = slides[0];
          } else {
            const index = slides.indexOf(slideStoraged);

            if (index < slides.length - 1) {
              nextSlideAnchor = slides[index + 1];
            } else {
              nextSlideAnchor = slides[0];
            }
          }

          const nextSlide = creativity.slides.find(
            (slide) => slide.anchor === nextSlideAnchor
          );

          localStorage.setItem(
            `${playlistWithVisibilityRules._id}_last-slide`,
            nextSlideAnchor
          );

          setSlide(nextSlide);
        }

        setLocation(enrichLocation(location));
        setPlayer(player);
        setCreative(caclTimeline(creativity));
        setCreativeStatus(200);
      });
  }, [creativeId]);

  useEffect(() => {
    if (playlist) {
      initPlaylistPlayer();
    } else {
      initPlayer();
    }

    window.BroadSignPlay = () => {
      setPlaying(true);
    };

    window.MVisionPlay = () => {
      setPlaying(true);
    };

    window.MVisionPause = () => {
      setPlaying(false);
    };

    if (document.location.search.match(/autoplay/)) {
      setPlaying(true);
    }
  }, [creativeId, initPlayer, initPlaylistPlayer, playlist]);

  useEffect(() => {
    const delay = PLAYER_FRAMERATE * MS;
    let playbackInterval;

    function updatePlayback() {
      if (playing && creative) {
        const { duration } = creative;
        const nextTime = currentTime + PLAYER_FRAMERATE;

        setCurrentTime(nextTime > duration ? 0 : nextTime);
      }
    }

    if (creativeStatus === 200) {
      playbackInterval = setInterval(updatePlayback, delay);
    }

    return function () {
      clearInterval(playbackInterval);
    };
  }, [creative, playing, creativeStatus, currentTime]);

  const syncPlayback = useCallback(function syncPlayback(currentTime) {
    if (playing) {
      setCurrentTime(currentTime);
    }
  }, [playing])

  function renderPlayer(className = "player", style = {}, double) {
    if (playlist) {
      // ? TODO: que se muestra cuando ninguna playlist cumple las rules?
      if (!slide) return <div></div>;

      return (
        <div
          className={className}
          style={transformCreative(player, style)}
          data-creative-id={creative._id}
          data-creative-name={creative.name}
        >
          <Slide
            slide={slide}
            currentTime={currentTime}
            playing={playing}
            syncPlayback={syncPlayback}
            player={player}
            location={location}
            lang={lang}
            params={parse(window.location.search)}
            creative={creative}
          />
        </div>
      );
    }

    if (double || (player && player.double)) {
      return (
        <div
          className="doubled"
          data-creative-id={creative._id}
          data-creative-name={creative.name}
        >
          <div className={className} style={transformCreative(player, style)}>
            {creative.slides.map((slide) => {
              return (
                <Slide
                  key={slide.name}
                  slide={slide}
                  currentTime={currentTime}
                  playing={playing}
                  syncPlayback={syncPlayback}
                  player={player}
                  location={location}
                  lang={lang}
                  params={parse(window.location.search)}
                  creative={creative}
                />
              );
            })}
          </div>
          <div className={className} style={transformCreative(player, style)}>
            {creative.slides.map((slide) => {
              return (
                <Slide
                  key={slide.name}
                  slide={slide}
                  currentTime={currentTime}
                  playing={playing}
                  syncPlayback={syncPlayback}
                  player={player}
                  location={location}
                  lang={lang}
                  params={parse(window.location.search)}
                  creative={creative}
                />
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <div
        className={className}
        style={transformCreative(player, style)}
        data-creative-id={creative._id}
        data-creative-name={creative.name}
      >
        {creative.slides.map((slide) => {
          return (
            <Slide
              key={slide.name}
              slide={slide}
              currentTime={currentTime}
              playing={playing}
              syncPlayback={syncPlayback}
              player={player}
              location={location}
              lang={lang}
              params={parse(window.location.search)}
              creative={creative}
            />
          );
        })}
      </div>
    );
  }

  if (creative) {
    const { width, height } = creative.format;
    const style = { width, height };

    if (isPreview()) {
      const corners = getPreviewCorners();
      const transform = getProjection(width, height, corners);
      const previewStyle = { ...style, transform };

      return (
        <div className="preview" style={{ height: window.innerHeight }}>
          <div className="glass" style={previewStyle} />
          {renderPlayer("screen", previewStyle)}
        </div>
      );
    }

    return renderPlayer(
      `player${isDoubled() ? " doubled" : ""}${isDoubled() ? " doubled" : ""}`,
      style
    );
  } else if (creativeStatus >= 400) {
    return <div className="error_screen">Error {creativeStatus}</div>;
  }

  return <div className="loading" />;
}

export default Player;
